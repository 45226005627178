import React, { Component } from 'react';
import moment from 'moment';
import { NavLink,withRouter } from "react-router-dom";
import TourDetails  from './page.tour.details';
import Global from '../globals/global';
const $ = window.$;

class landing extends Global{

    constructor(props) {
    super(props);


  // this.config().landinginfo = {Footer:'<div class="row"><div class="col-md-6"><p class="text_group_bott">This special site is just for YOU -- complete with our own destination concierge who can assist with answering your questions, making plans and booking activities --<b class="blue_color_g"> EXPERIENCED LOCAL PROFESSIONALS YOU CAN COUNT ON</b><br> <br>Top Quality, Service and Great Prices so <b class="phone_traficag">YOU GET THE BEST EXPERIENCES POSSIBLE.</b><br> <br><span>FOR ASSISTANCE</span> <br> with our Concierge 9:00am to 6:00pm Local Time Daily. <br><br><b> CALL TOLL FREE BEFORE YOUR ARRIVE</b>, 1-855-847-5333 <br>OR OUR LOCAL OFFICE DURING YOUR STAY: (998) 193 0573</p></div><div class="col-md-6"><img src="img/family_t1.png" class="img-fluid"></div></div>',"Id_PartnerLandingPage":"103","Name":"Shawn & Zuiggly's Wedding","Description":"","Description_2":"","Destination":"","TourName":"Isla Mujeres - Luxury Sailing Cruise","Id_Tour":"574","TourUrlName":"isla-mujeres-luxury-sailing","HotelName":"Hotel Allegro Playacar","Id_Hotel":"1422","Id_Destination":"1","Id_Location":"2","SpecialOffer":"SAVE BOOKING ONLINE! CODE: SZ041720\t","ActivityDate":"2019-12-31","CouponCode":"SZ041720","Id_Agency":"6441","Id_Agent":"6549","MeetingPointTime":"Lobby of the Hotel at 09:40 AM","SoldOut":"0","AvailableTickets":"60","Id_Coupon":null,"CRC32":"cbada3f8","ImageFileName":"","TourSpecification":"","ActivityTime":"11:30 AM","ActivityDateTime":"April 17th, 2020. 11:30 AM","Id_Admin":null,"EnableTransfers":"0","isActive":"-1","ExpirationDate":"2020-04-16","UrlName":"shawn-zuiggly-wedding","Created":"2012-01-01 00:00:00","Modified":"2019-12-21 10:03:00","SortPosition":"0","GroupName":"Shawn & Zuiggly's Wedding","CustomServicePhoneNum":"(998) 193 0560","CustomID":"CUN-1416-0455","result":true,"tours":[],"_SessionLang":"","_Id_Currency":null,"isFromCache":false}
    this.state ={
       landinginfo:this.config().landinginfo
      }

  }

    componentDidMount(){

      $('header').addClass('tour_bg');

      var landing = this.state.landinginfo;
        if(landing){
          if(landing.ExpirationDate != ''){
            if(moment(landing.ExpirationDate) < moment()) this.preventpopup();
          }
        }

     /* if (!this.getglobaldata("filter")) this.setglobaldata("filter", {});

      if (!this.getglobaldata("filter.touravailability")) this.setglobaldata("filter.touravailability", {});

      this.setglobaldata('filter.touravailability.activitytime',moment(this.state.landinginfo.ActivityTime, 'HH:mm a'))
      this.setglobaldata('filter.touravailability.activitydate',moment(this.state.landinginfo.ActivityDate))

      console.log('filter.touravailability.activitydate',moment(this.state.landinginfo.ActivityTime, 'HH:mm a'));
      console.log('filter.touravailability.activitytime',moment(this.state.landinginfo.ActivityDate));
      console.log('filter.touravailability',this.getglobaldata('filter.touravailability'));*/

    }

    componentWillMount__(){

   /*  if(!this.state.landinginfo) return;

      if (!this.getglobaldata("filter")) this.setglobaldata("filter", {});

      if (!this.getglobaldata("filter.touravailability")) this.setglobaldata("filter.touravailability", {});

      this.setglobaldata('filter.touravailability.activitytime',moment(this.state.landinginfo.ActivityTime, 'HH:mm a'))
      this.setglobaldata('filter.touravailability.activitydate',moment(this.state.landinginfo.ActivityDdate))*/
    }

    componentWillUnmount() {
        $('header').removeClass('tour_bg');
    }

    preventpopup(){
   

    $.magnificPopup.open({
      items: {
        src: "#preventp"
      },
      type: 'inline',
      fixedContentPos: true,
      fixedBgPos: true,
      overflowY: 'auto',
      closeBtnInside: true,
      preloader: false,
      midClick: true,
      removalDelay: 300,
      closeOnBgClick:false,
      closeBtnInside:true,
      mainClass: 'my-mfp-zoom-in',
      callbacks: {
        open: function() {



        }.bind(this),

        close: function() {

        }.bind(this)
      }
    });

  
   }



    render() {
  

    if(!this.state.landinginfo) return null;

    let landing = this.state.landinginfo;

    var phone = '1-855-847-5333';
       

        return (
            <div>
            
            <section>
  <div className="container">
    <div className="group_ac_content">
      <div className="animated fadeInDown">
        <h1 className="title_group_ac">{this.t('Join the Featured Group Activity')}</h1>
        <p className="text_d_group_ac">
          {landing.Name} - {landing.HotelName}
        </p>
        <p className="text_d_group_ac_b">{this.t('Group Tour Event:')}<span className="blue_color_g"> {landing.TourName}</span> {this.t('for')} {landing.ActivityDateTime}
        </p>
      </div>

     {landing.CouponCode != ''?
      <div className="animated fadeInDown group_offer">
                <div className="col-md-12">
                  <h1 className="title_group_ac">{landing.SpecialOffer}</h1>
                  <br/>
                  <p>YOUR DISCOUNT CODE: <span>{landing.CouponCode}</span></p>
                </div>
            </div>:''
}
    </div>

  </div>
</section>;
{/* End section */}


 <TourDetails params={{disablemodule:true,tourid:landing.Id_Tour,soldout:landing.SoldOut}} />

<main>
        <div className="container">

          <div className="row">

            <div className="col-lg-12">
              <div className="card">

              <div className="card-body bor_t" dangerouslySetInnerHTML={{ __html:landing.Footer}}></div>


              </div>
            </div>
          </div>
          {/*End row */}
        </div>
        {/*End container */}
        <div id="overlay" />
        {/* Mask on input focus */}
      </main>


        {/* Prevent Popup */}
  <div id="preventp" className="zoom-anim-dialog mfp-hide">
    <div className="small-dialog-header"></div>
    <div className="sign-in-wrapper text-center">
      <div className="tour_container">
        <div className="tour_title">
          <h3 className="blue_color_g">
            <strong>
              {this.t('Online Bookings for')} {landing.Name} <br />
              - {landing.TourName} - {landing.ActivityDateTime}, <br />
              {this.t('are not longer available.')}
            </strong>
          </h3>
          <br />
          <span className="text-justify">
            <strong dangerouslySetInnerHTML={{ __html: this.t('Please contact your local host/representative at the hotel to book <br /> additional excursions.')}}></strong>
          </span>
        </div>
      </div>
    </div>
  </div>



</div>

        )
    }

}

export default landing