module.exports = {
    gaid:'UA-40645563-8', //Google Analtics Id  //Totoura Account
    gtmid:false,  //Google Tag Manager Id
    email:'info@totoura.com',
    phone:'1-855-847-5333',
    sbiid:-1, //wepartner/indestinationstore
    activityrateid:1,
    brand:'bookingwidget5',
    brandname:'Booking Widget 5',
    basename:'bookingwidget5',
    logo:'bookingwidget',
    baseurl:"bookingwidget5",
    apiurl:'https://api-trafictours-sandbox.mobilesail.com/',
    imagescdn:'https://cdn-trafictours-sandbox.mobilesail.com/images/',
    cdn:'https://cdn.mobilesail.com/reactproject/',
    noheaderpages:['login','travelagentmessage','mitmessage','bancomermessage'],
    disablebooking:false,
    destinationscountries:'all', //todo   //'167,65'   // 'all',
    destinationsregions:'all', //todo  //'1,2,3'   // 'all'
    localwhislist:false,
    defaultlanguageid:1,  //1=englis, 2=spanish, 3:french,
    defaultcurrencyid:1,  //1=usd, 2=mxn, 3=cad, 4=eur
    languagesdropdown:'all', //todo   
    travelagentid:null,   //undefined
    travelagencyid:null,   //undefined,
    transfers:true,
    experiences:false,
    header:true,
    footer:true,
    myaccount:true,
    shopping:true,
    dining:true,
    languages:true,
    currencies:true,
    tours:true,
    home:true,
    trending:true,
    specials:true,
    wishlist:true,
    contact:true,
    search:true,
    weather:false,
    destinations:true,
    threextwo:true,
    mailinfo:{ 
               brand:'Booking Widget',
               logo:'https://cdn.mobilesail.com/reactproject/img/mail_bookingwidget.png',
               background:'#31b0c0'
             },
    loftloaderoptions:{
                bg_color:                      '#31b0c0',
                bg_gradient:                   true,
                bg_gradient_start_color:       '#31b0c0',
                bg_gradient_end_color:         '#9bccd2',
                bg_gradient_angel:             215,
                bg_opacity:                    1,
                bg_animation:                  'fade',
                loader_type:                   'imgloading',
                loader_image:                  'img/loader_bookingwidget.png',
                loader_image_width:            '254px',
                loader_direction:              'vertical',
                loader_looping:                'once',
                progress_type:                 'none',
                message_text:                  '',
                message_size:                  '19px',
                message_color:                 '#ffffff',
                load_time:                     1
    }
}